<template>
  <div class="mfooter-padding">
    <div class="text-center px-10">
      <h2 class="title p-relative mt-5 mb-5 weight-100">
        {{ $t('Select a category') }}
          <router-link
            :to="{
              name: 'SastechCategoryM'
            }"
            tag="a"
            exact
          >
            <img
              src="/new-assets/icon/long-arrow-left-light.svg"
              alt=""
              class="left-arrow pointer"
            />
          </router-link>
      </h2>

      <section class="log-filter df">
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`טאצ'`)"
          ref="mfטאצ'"
        >
          <img
            src="/new-assets/icon/TOUCH.png"
            alt=""
            style="height: 38px; width: auto"
          />
        </div>
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`גרין קר`)"
          ref="mfגרין קר"
        >
          <img src="/new-assets/icon/GC.png" alt="" />
        </div>
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`מג'יק`)"
          ref="mfמג'יק"
        >
          <img
            src="/new-assets/icon/download.jpg"
            alt=""
            style="width: 40px; height: 40px"
          />
        </div>
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`מאקסול- מקס`)"
          ref="mfמאקסול- מקס"
        >
          <img src="/new-assets/icon/MM.png" alt="" />
        </div>
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`LOVELY`)"
          ref="mfLOVELY"
        >
          <img src="/new-assets/icon/LOVELY.png" alt="" style="width: 100%" />
        </div>
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`ביוטי קלטור`)"
          ref="mfביוטי קלטור"
        >
          <img src="/new-assets/icon/BC.png" alt="" style="width: 100%" />
        </div>
        <div
          class="logo-filter_btn pointer"
          @click="getProductByManufacture(`נובו`)"
          ref="mfנובו"
        >
          <img src="/new-assets/icon/NOVO.png" alt="" style="width: 100%" />
        </div>
      </section>

      <section>
        <template v-for="item in products">
          <div
            class="sastech-product"
            :key="item.id"
            :class="item.isChecked ? 'checked' : ''"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 10%"
            >
              <v-checkbox
                color="#0D3856"
                class="cus-checkbox"
                v-model="item.isChecked"
              ></v-checkbox>
            </div>
            <div
              class="d-flex align-items-center justify-content-center bg-white img-block"
              style="width: 20%"
            >
              <img
                :src="item.image || '/new-assets/img/shaniv-logo.png'"
                alt=""
              />
            </div>
            <div class="p-relative des-block" style="width: 70%">
              <p>{{ getTagString(item.tags) }}</p>
              <p>{{ item[currentLang + '_name'] }}</p>
              <p class="color-grey">{{ item[currentLang + '_description'] }} ({{ $t('Package quantity') }}: {{ getProductPackageQuantity(item) }})</p>
              <p>
                <b>{{ getProductPackageDiscountPrice(item) }} ₪</b>
                <span> ({{ $t('Unit price') }} {{ item.DISCOUNT_VATPRICE }} ₪)</span>
              </p>
              <div class="slider-footer__add-btn">
                <div
                  class="slider-footer__add-img cursor-pointer df"
                  v-on:click="onIncreaseQuality(item)"
                >
                  <img src="/new-assets/icon/plus-circle-solid.svg" alt="" />
                </div>
                <div class="footer__addbtn-p df-c">
                  <p>{{ getTempQuantity(item) }}</p>
                </div>
                <div
                  class="footer__addbtn-img cursor-pointer df"
                  v-on:click="onDecreaseQuality(item)"
                >
                  <img src="/new-assets/icon/minus-circle-solid.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </section>
    </div>
    <footer>
      <div>
        <p><b>₪ {{ tempTotalPrice }}</b></p>
        <p>{{ $t('Before discount') }}</p>
      </div>
      <button 
        class="primary-btn bg-blue add-basket_btn"
        @click='addToCart()'
      >
        <img
          src="/new-assets/icon/shopping-cart-light.svg"
          alt=""
          class="mx-2"
        />
        <p>{{ $t('Add basket') }}</p>
      </button>
    </footer>
    <loading
      :active.sync="getProductsInProgress"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'SastechCategoryM',
  components: {
    Loading,
  },
  data: () => {
    return {
      selectedTags: [],
      selectedMf: [],
      tempCart: {},
      tempProduct: {}
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.productShaniv.products,
      getProductsInProgress: (state) =>
        state.productShaniv.getProductsInProgress,
      cart: (state) => state.cartSystem.cart,
      shanivTags: (state) => state.productShaniv.shanivTags,
    }),
    tempTotalPrice() {
      let tempTotalPrice = 0;
      for (let key in this.products) {
        let product = this.products[key];
        if (!product.isChecked) {
          continue;
        }

        let tempQuantity = this.getTempQuantity({ id: product.id });

        tempTotalPrice += this.calculateProductQuantityPrice({
            DISCOUNT_VATPRICE: product.DISCOUNT_VATPRICE,
            quantity: tempQuantity,
            qtySteps: product.qtySteps
        });
      }
      tempTotalPrice = Math.round((tempTotalPrice + Number.EPSILON) * 100) / 100;

      return tempTotalPrice;
    }
  },
  created() {
    this.initCart();
    this.loadProducts();

    if (this.shanivTags.length == 0) {
      this.getShanivTags();
    }
  },
  methods: {
    ...mapActions('productShaniv', {
      getProducts: 'getProducts',
      getShanivTags: 'getShanivTags',
    }),
    ...mapActions('cartSystem', {
      addProductsToCart: 'addProductsToCart',
      initCart: 'initCart',
    }),
    ...mapActions('alert', {
      error: 'error'
    }),
    getProductByManufacture(mf) {
      this.tempProduct = {};
      this.tempPrice = 0;
      if (!this.selectedMf.includes(mf)) {
        this.selectedMf.push(mf);
        this.$refs['mf' + mf].classList.add('active');
      } else {
        this.removeA(this.selectedMf, mf);
        this.$refs['mf' + mf].classList.remove('active');
      }
      this.loadProducts();
    },
    onIncreaseQuality({ id, isChecked }) {
      if (!isChecked) {
        return false;
      }

      if (typeof this.tempCart[id] === 'undefined') {
        this.tempCart[id] = 1;
      }

      this.tempCart[id]++;

      this.tempCart = Object.assign({}, this.tempCart);
    },
    onDecreaseQuality({ id, isChecked }) {
      if (!isChecked) {
        return false;
      }

      if (typeof this.tempCart[id] === 'number' && this.tempCart[id] > 1) {
        this.tempCart[id]--;
      }

      this.tempCart = Object.assign({}, this.tempCart);
    },
    getTempQuantity({ id }) {
      return (typeof this.tempCart[id] === 'number') ? this.tempCart[id] : 1;
    },
    clearTempQuantity({ id }) {
      this.tempCart[id] = 1;
      this.tempCart = Object.assign({}, this.tempCart);
    },
    getTempTotalCount({ id, qtySteps }) {
      let tempQuantity = this.getTempQuantity({ id });
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return tempQuantity * qtySteps;
    },
    getTagString(tagArray) {
      let tagStr = '';
      this.shanivTags.forEach(el => {
        if (tagArray.includes(el.id)) {
          tagStr += el.name + ', '
        }
      });
      if (tagStr != '') {
        tagStr = tagStr.slice(0, -2);
      }
      return tagStr;
    },
    addToCart() {
      if (Object.keys(this.tempCart).length != 0 && this.tempCart.constructor === Object) {
        for (const [product_id, tempQuantity] of Object.entries(this.tempCart)){
          const quantity = this.getTempQuantity({ id: product_id });
          const product = this.products.filter(function (product) {
            return product.id == product_id
          })[0];
          
          this.addProductsToCart({
            product,
            quantity,
            company: product.company,
            type: 'products'
          });
        };
      }

      this.$router.push({ name: 'CartM' });
    },
    loadProducts() {
      let params = {
        active: 1,
        user_prices: 1
      };

      if (this.selectedTags) {
        params.tag = this.selectedTags;
      }
      if (this.selectedMf) {
        params.SPEC14 = this.selectedMf;
      }

      this.getProducts(params);
    },
    getProductPackageQuantity({ id, qtySteps }) {
      return qtySteps && qtySteps > 0 ? qtySteps : 1;
    },
    getProductPackageDiscountPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackagePrice({ id, qtySteps, DISCOUNT_VATPRICE, VATPRICE }) {
      VATPRICE = VATPRICE >= DISCOUNT_VATPRICE ? VATPRICE : DISCOUNT_VATPRICE;
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getTempToCartPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      let tempQuantity = this.getTempQuantity({ id });

      return this.calculateProductQuantityPrice({ DISCOUNT_VATPRICE: DISCOUNT_VATPRICE, quantity: tempQuantity, qtySteps: qtySteps});
    },
    calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    }
  },
};
</script>


<style lang="scss" scoped>
.left-arrow {
  position: absolute;
  right: 0px;
  top: 15px;
}
.log-filter {
  justify-content: center;
  flex-wrap: wrap;
  .logo-filter_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #234395;
    background-color: #fff;
    width: 80px;
    height: 50px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    img {
      width: 80%;
    }
    &.active {
      box-shadow: 0 3px 6px #222;
      border-width: 3px;
    }
  }
}
.sastech-product {
  display: flex;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 100px;
  background-color: #eee;
  p {
    text-align: initial !important;
    height: 22px;
  }
  .img-block {
    border-left: 1px solid #dfdede;
    border-right: 1px solid #dfdede;
    height: 100%;
    img {
      max-height: 80%;
      max-width: 100%;
      object-fit: contain;
    }
  }
  .des-block {
    padding: 5px 10px;
  }
  &.checked {
    background-color: #f2fcff;
    border-color: #2ac0ee;
    .slider-footer__add-btn {
      background-color: #b9e3f1;
    }
    // border-top: 2px solid #2ac0ee;
    // border-bottom: 2px solid #2ac0ee;
  }
}
.slider-footer__add-btn {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.add-basket_btn {
  border-radius: 20px;
  height: 38px;
  width: auto;
  padding: 0 20px;
  font-size: 16px;
}
</style>